const util = {
  isObjEmpty: (obj) => {
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  },
  isEmpty: (value) =>
    value === undefined ||
    value === null ||
    (typeof value === "object" && Object.keys(value).length === 0) ||
    (typeof value === "string" && value.trim().length === 0),
  pad: (id, size) => {
    let strId = id.toString();

    while (strId.length < (size || 2)) {
      strId = "0" + strId;
    }

    return strId;
  },
  generateRandomString: () => {
    return Math.random().toString(20).substr(2, 6).toUpperCase();
  },
};

export default util;
