/* Components */
import { useState } from "react";
import {
  Box,
  Form,
  Button,
  Heading,
  Image,
  Modal,
} from "react-bulma-components";

/* Actions */
import { signup } from "../../../../actions/web/signup.actions";

/* Assets */
// import RainyDayFlyer from "../../../../assets/img/rainy-day-rewards-flyer.png";
import DecemberFlyer from "../../../../assets/img/patricias-december-flyer.png";

/* Others */
import util from "../../../../util/util";
import { AlertSuccess } from "../../../layout/common/alerts-component";

const { Field, Label, Control, Input } = Form;
const { Group } = Button;
const { Card } = Modal;

const { Header, Body, Title } = Card;

const validateData = ({ firstName, lastName, mobileNumber, email }) => {
  let err = {};

  if (firstName === "" || firstName === null) {
    err = { ...err, firstName: "This field is required" };
  }
  if (lastName === "" || lastName === null) {
    err = { ...err, lastName: "This field is required" };
  }
  if (mobileNumber === "" || mobileNumber === null) {
    err = { ...err, mobileNumber: "This field is required" };
  }
  if (
    mobileNumber.length !== 11 ||
    mobileNumber[0] !== "0" ||
    (mobileNumber[0] !== "0" && mobileNumber[1] !== "9")
  ) {
    err = { ...err, mobileNumber: "Invalid Mobile Number" };
  }

  if (email !== "" && !email.includes("@")) {
    err = { ...err, email: "Invalid Email" };
  }

  return err;
};

const SignUp = () => {
  const [data, setData] = useState({
    firstName: "",
    lastName: "",
    mobileNumber: "",
    email: "",
  });

  const [showModal, setShowModal] = useState(false);
  const [errors, setErrors] = useState({});

  const onInputChange = (e) => {
    let err = { ...errors };
    delete err[e.target.name];
    setErrors(err);
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const onSubmit = (e) => {
    e.preventDefault();

    const err = validateData(data);

    if (util.isObjEmpty(err)) {
      const res = signup(data);

      res
        .then((res) => {
          // Alert success notification here
          if (res.status === 200) {
            AlertSuccess(
              "Success!",
              "Your registration on Patricia's Kitchen Rewards was successful"
            );

            setData({
              firstName: "",
              lastName: "",
              mobileNumber: "",
              email: "",
            });
          }
        })
        .catch((error) => {
          const { response } = error;
          const { data } = response;

          setErrors(data.errors);
        });
    } else {
      setErrors(err);
    }
  };

  const onClickShowModal = () => {
    setShowModal(!showModal);
  };

  return (
    <Box style={{ width: 400, margin: "auto", marginTop: "1.5rem" }}>
      <Image src={DecemberFlyer} />
      <Heading
        className="has-text-primary"
        size={4}
        style={{ marginTop: ".5rem" }}
      >
        Register
      </Heading>
      <form onSubmit={onSubmit}>
        <Field>
          <Label className="has-text-primary">First Name</Label>
          <Control>
            <Input
              type="text"
              name="firstName"
              placeholder="Juan"
              value={data.firstName}
              onChange={onInputChange}
              color={errors && errors.firstName ? "danger" : "default"}
              required={true}
            />
          </Control>
          {errors && errors.firstName ? (
            <p className="help is-danger">{errors.firstName}</p>
          ) : null}
        </Field>
        <Field>
          <Label className="has-text-primary">Last Name</Label>
          <Control>
            <Input
              type="text"
              name="lastName"
              placeholder="Dela Cruz"
              value={data.lastName}
              onChange={onInputChange}
              color={errors && errors.lastName ? "danger" : "default"}
              required={true}
            />
          </Control>
          {errors && errors.lastName ? (
            <p className="help is-danger">{errors.lastName}</p>
          ) : null}
        </Field>
        <Field>
          <Label className="has-text-primary">Mobile Number</Label>
          <Control>
            <Input
              type="text"
              name="mobileNumber"
              placeholder="09*********"
              value={data.mobileNumber}
              onChange={onInputChange}
              color={errors && errors.mobileNumber ? "danger" : "default"}
              require={true}
            />
          </Control>
          {errors && errors.mobileNumber ? (
            <p className="help is-danger">{errors.mobileNumber}</p>
          ) : null}
        </Field>
        <Field>
          <Label className="has-text-primary">Email</Label>
          <Control>
            <Input
              type="text"
              name="email"
              placeholder="customer@gmail.com"
              value={data.email}
              onChange={onInputChange}
              color={errors && errors.email ? "danger" : "default"}
            />
          </Control>
          {errors && errors.email ? (
            <p className="help is-danger">{errors.email}</p>
          ) : null}
        </Field>
        <Group align="center">
          <Button color="primary" fullwidth={true}>
            Register
          </Button>
        </Group>
      </form>
      <Group style={{ marginTop: ".5rem" }}>
        <Button color="primary" fullwidth={true} onClick={onClickShowModal}>
          View Terms and Conditions
        </Button>
      </Group>
      <Modal onClose={onClickShowModal} show={showModal}>
        <Card>
          <Header>
            <Title className="has-text-primary">Terms and Conditions</Title>
          </Header>
          <Body>
            <Heading className="has-text-primary mb-0" size={5}>
              Conditions:
            </Heading>
            <ol style={{ padding: ".5rem 1rem" }}>
              <li>
                Get 20% cash-back for one-time food bill of at least P1,000
              </li>
              <li>
                To enjoy a 20% discount on your succeeding orders, the purchase
                amount must be at least P1,000
              </li>
              <li>Alcohol and other drinks are not included in the promo.</li>
            </ol>
            <Heading className="has-text-primary mb-0" size={5}>
              How to Join and avail of the promo:
            </Heading>
            <ol style={{ padding: ".5rem 1rem" }}>
              <li>
                Register at{" "}
                <a href="https://patricia.cloudph.net/">
                  https://patricia.cloudph.net
                </a>
              </li>
              <li>
                Customer must register their mobile number to receive customer
                ID and promo codes via SMS
              </li>
              <li>
                Present your customer id and promo code to the cashier before
                placing the order to avail the promo.
              </li>
            </ol>
          </Body>
        </Card>
      </Modal>
    </Box>
  );
};

export default SignUp;
