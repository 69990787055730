/* Packages */
import { Fragment, useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import {
  Table,
  Box,
  Heading,
  Content,
  Button,
  Block,
} from "react-bulma-components";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";

/* Components */
import Loading from "../../../layout/common/loading.component";

/* Reducer */
import {
  selectCustomer,
  setCustomer,
  setCustomerCouponAsClaimed,
} from "../../../../reducers/customer.reducer";
import {
  getCustomer,
  updateCustomer,
} from "../../../../actions/admin/customer.actions";
import { claimCoupon } from "../../../../actions/admin/coupon.actions";

/* Util */
import util from "../../../../util/util";

const { Container } = Table;
const { Group } = Button;

const Customer = () => {
  let { id } = useParams();
  const history = useHistory();
  const data = useSelector(selectCustomer);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);

  const onClickTagAsFreebieClaimed = () => {
    setIsLoading(true);
    const data = {
      FreebiesClaimed: true,
      FreebiesClaimedDate: moment(),
    };
    const response = updateCustomer(id, data);
    response.then((res) => {
      if (res.status === 200) dispatch(setCustomer(res.data));

      setIsLoading(false);
    });
  };

  const onClickClaim = (cid, couponId) => {
    const uCoupon = claimCoupon(cid, couponId);
    uCoupon
      .then((res) => {
        if (res.status === 200) {
          dispatch(setCustomerCouponAsClaimed(res.data));
        }
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    const response = getCustomer(id);
    response.then((res) => {
      if (res.status === 200) dispatch(setCustomer(res.data));

      setIsLoading(false);
    });
    // if (!data || util.isObjEmpty(data)) {
    //   const response = getCustomer(id);
    //   response.then((res) => {
    //     if (res.status === 200) dispatch(setCustomer(res.data));

    //     setIsLoading(false);
    //   });
    // } else {
    //   setIsLoading(false);
    // }
  }, []);

  return (
    <Fragment>
      {isLoading ? (
        <Loading />
      ) : (
        <Fragment>
          <header>
            <Heading
              size={3}
              textColor="primary"
              className="pb-3"
              weight="bold"
            >
              {`${data.FirstName} ${data.LastName}`}
            </Heading>
          </header>
          <Box>
            <Content>
              <Heading size="5" textColor="primary" weight="bold">
                Details
              </Heading>
              <p>
                Mobile Number:{" "}
                <span className="is-size-6 has-text-weight-bold">
                  {data.MobileNumber}
                </span>
              </p>
              <p>
                Email:{" "}
                <span className="is-size-6 has-text-weight-bold">
                  {data.Email}
                </span>
              </p>
              <p>
                Freebie Claimed:{" "}
                <span className="is-size-6 has-text-weight-bold">
                  {data.FreebiesClaimed ? "Yes" : "No"}{" "}
                  {data.FreebiesClaimed ? (
                    ""
                  ) : (
                    <Button
                      size="small"
                      color="primary"
                      onClick={onClickTagAsFreebieClaimed}
                    >
                      Tag as Claimed
                    </Button>
                  )}
                </span>
              </p>
              <p>
                Freebie Claimed Date:{" "}
                <span className="is-size-6 has-text-weight-bold">
                  {data.FreebiesClaimedDate
                    ? moment(data.FreebiesClaimedDate).format(
                        "dddd MMMM DD, YYYY hh:mm A"
                      )
                    : ""}
                </span>
              </p>
              <p>
                Validated:{" "}
                <span className="is-size-6 has-text-weight-bold">
                  {data.Activated ? "Yes" : "No"}
                </span>
              </p>
              <p>
                Registration Date:{" "}
                <span className="is-size-6 has-text-weight-bold">
                  {moment(data.createdAt).format("dddd MMMM DD, YYYY hh:mm A")}
                </span>
              </p>
            </Content>
          </Box>
          <Box>
            <Block display="flex" justifyContent="space-between">
              <Heading size="5" textColor="primary" weight="bold">
                Orders
              </Heading>
              <Button
                onClick={() => history.push(`/orders/add/${id}`)}
                size="small"
                color="primary"
              >
                Add
              </Button>
            </Block>
            <Container>
              <Table bordered size="fullwidth">
                <thead>
                  <tr>
                    <th>Reference #</th>
                    <th>Amount</th>
                    <th>Order Date</th>
                    <th>Coupon Used</th>
                    <th>Discount</th>
                    <th>Total</th>
                  </tr>
                </thead>
                <tbody>
                  {data.orders && data.orders.length > 0 ? (
                    data.orders.map((order) => (
                      <tr key={order.id}>
                        <td>{order.ReferenceNumber}</td>
                        <td>{order.Amount}</td>
                        <td>{order.OrderDate}</td>
                        <td>{order.CouponCode ? order.CouponCode : ""}</td>
                        <td>{order.Discount}</td>
                        <td>{order.Total}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="6" style={{ textAlign: "center" }}>
                        No Data
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </Container>
          </Box>
          <Box>
            <Heading size="5" textColor="primary" weight="bold">
              Coupons
            </Heading>
            <Container>
              <Table bordered size="fullwidth">
                <thead>
                  <tr>
                    <th>Order Ref #</th>
                    <th>Coupon Code</th>
                    <th>Cash-back %</th>
                    <th>Claimed</th>
                    <th>Claimed Date</th>
                  </tr>
                </thead>
                <tbody>
                  {data.coupons && data.coupons.length > 0 ? (
                    data.coupons.map((coupon) => (
                      <tr key={coupon.CouponCode}>
                        <td>{coupon.ReferenceNumber}</td>
                        <td>{coupon.CouponCode}</td>
                        <td>{coupon.CashBackPercentage}</td>
                        <td>{coupon.IsClaimed ? "Yes" : "No"}</td>
                        <td>
                          {coupon.IsClaimed
                            ? moment(coupon.ClaimedDate).format("YYYY-MM-DD")
                            : ""}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="5" style={{ textAlign: "center" }}>
                        No Data
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </Container>
          </Box>
        </Fragment>
      )}
    </Fragment>
  );
};

export default Customer;

// <td>
//   {!moment().isBefore(coupon.ExpirationDate) ||
//   !coupon.IsClaimed ? (
//     <Group size="small" align="center">
//       <Button
//         color="primary"
//         onClick={(e) => {
//           e.preventDefault();
//           onClickClaim(data.id, coupon.CouponCode);
//         }}
//       >
//         Claim
//       </Button>
//     </Group>
//   ) : (
//     ""
//   )}
// </td>
