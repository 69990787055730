/* Packages */
import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";

/** Components */
import { isLoggedIn } from "../reducers/auth.reducer";

const PrivateRoute = (props) => {
  const isAuth = useSelector(isLoggedIn);

  return isAuth ? <Route {...props} /> : <Redirect to="/auth" />;
};

export default PrivateRoute;
