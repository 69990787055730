/* Package */
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

/* Components */
import SignUp from "./component/pages/web/signup/signup.component";
import Login from "./component/pages/admin/login/login.component";
import Dashboard from "./component/pages/admin/dashboard/dashboard.component";
import Customers from "./component/pages/admin/customer/customers.component";
import Customer from "./component/pages/admin/customer/customer.component";
import Orders from "./component/pages/admin/order/orders.component";
import AddOrder from "./component/pages/admin/order/order.add.component";

/* Layout */
import AdminLayoutRoute from "./component/layout/admin/admin.layout";

import NotFound from "./component/layout/common/notfound.component";

// /* Actions */
// import { isLoggedIn } from "./reducers/auth.reducer";

/* Styles */
import "./component/layout/admin/sidebar.scss";
import "./App.sass";
import "./App.css";

function App() {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route exact path="/" component={SignUp} />
          <Route exact path="/auth" component={Login} />
          <AdminLayoutRoute exact path="/dashboard" component={Dashboard} />
          <AdminLayoutRoute exact path="/customers" component={Customers} />
          <AdminLayoutRoute exact path="/customers/:id" component={Customer} />
          <AdminLayoutRoute exact path="/orders" component={Orders} />
          <AdminLayoutRoute exact path="/orders/add/:id" component={AddOrder} />
          <Route path="*" component={NotFound} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
