/* Packages */
import { useDispatch, useSelector } from "react-redux";
import {
  ProSidebar,
  SidebarHeader,
  SidebarFooter,
  SidebarContent,
  Menu,
  MenuItem,
} from "react-pro-sidebar";
import { Link, useLocation } from "react-router-dom";
import { FaTachometerAlt, FaUsers } from "react-icons/fa";
import { RiCoupon5Fill, RiLogoutBoxLine } from "react-icons/ri";
import { HiOutlineDocumentReport } from "react-icons/hi";

/* Action */
import { unsetUser } from "../../../reducers/auth.reducer";
import {
  setSidebarToggle,
  selectSidebarToggle,
  selectSidebarCollapse,
} from "../../../reducers/settings.reducer";

/* Others */
import Logo from "../../../assets/img/patricias-logo.png";
import "react-pro-sidebar/dist/css/styles.css";

const Sidebar = () => {
  const location = useLocation();
  const toggled = useSelector(selectSidebarToggle);
  const collapsed = useSelector(selectSidebarCollapse);
  const dispatch = useDispatch();

  const onClickLogout = () => {
    dispatch(unsetUser());
  };

  return (
    <ProSidebar
      toggled={toggled}
      collapsed={collapsed}
      breakPoint="md"
      onToggle={(value) => dispatch(setSidebarToggle(value))}
    >
      <SidebarHeader>
        {/**
         *  You can add a header for the sidebar ex: logo
         */}
        <div
          style={{
            padding: "24px",
            textTransform: "uppercase",
            fontWeight: "bold",
            fontSize: 14,
            letterSpacing: "1px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          <img src={Logo} alt="Patricias Kitchen" />
        </div>
      </SidebarHeader>
      <SidebarContent>
        {/**
         *  You can add the content of the sidebar ex: menu, profile details, ...
         */}
        <Menu iconShape="circle">
          {/*
          <MenuItem
            icon={<FaTachometerAlt />}
            active={location.pathname.includes("dashboard") ? true : false}
          >
            Dashboard <Link to="/dashboard" />
          </MenuItem>
        */}
          <MenuItem
            icon={<FaUsers />}
            active={location.pathname.includes("customers") ? true : false}
          >
            Customers <Link to="/customers" />
          </MenuItem>
          <MenuItem
            icon={<HiOutlineDocumentReport />}
            active={location.pathname.includes("order") ? true : false}
          >
            Orders <Link to="/orders" />
          </MenuItem>
          {/*<MenuItem icon={<RiCoupon5Fill />}>Claims</MenuItem>*/}
        </Menu>
      </SidebarContent>
      <SidebarFooter>
        {/**
         *  You can add a footer for the sidebar ex: copyright
         */}
        <Menu iconShape="circle">
          <MenuItem icon={<RiLogoutBoxLine />} onClick={onClickLogout}>
            Logout
          </MenuItem>
        </Menu>
      </SidebarFooter>
    </ProSidebar>
  );
};

export default Sidebar;
