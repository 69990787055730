/* Packages */
import { createSlice } from "@reduxjs/toolkit";
import jwt_decoded from "jwt-decode";

/* Util */
import setAuthToken from "../util/setAuthToken";
import util from "../util/util";

const checkIfAuthenticated = () => {
  let auth = {};
  if (localStorage.jwtToken) {
    setAuthToken(localStorage.jwtToken);
    const decoded = (auth = jwt_decoded(localStorage.jwtToken));
    const currentTime = Date.now() / 1000;

    if (decoded.exp < currentTime) {
      localStorage.removeItem("jwtToken");
      setAuthToken(false);
      auth = {};
      window.location.href = "/auth";
    }
  }

  return auth;
};

const initialState = {
  user: checkIfAuthenticated(),
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    unsetUser: (state) => {
      localStorage.removeItem("jwtToken");
      setAuthToken(false);
      state.user = {};
      window.location.href = "/auth";
    },
  },
});

export const { setUser, unsetUser } = authSlice.actions;

export const getUser = (state) => state.auth.user;
export const isLoggedIn = (state) =>
  !util.isObjEmpty(state.auth.user) ? true : false;

export default authSlice.reducer;
