/* Packages */
import { Block, Progress } from "react-bulma-components";

const Loading = () => {
  return (
    <Block>
      <h4 className="is-size-6">Loading ...</h4>
      <Progress max={100} size="small" color="link" value={null} mt={1} />
    </Block>
  );
};

export default Loading;
