/* Packages */
import { Fragment, useState } from "react";
import { Box, Heading, Form, Button, Columns } from "react-bulma-components";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import moment from "moment";

/* Components */
import Loading from "../../../layout/common/loading.component";
import {
  AlertError,
  AlertSuccessSave,
} from "../../../layout/common/alerts-component";

/* Actions */
import {
  setCustomerOrdersAndCoupons,
  selectCustomerCoupons,
} from "../../../../reducers/customer.reducer";
import { addOrder } from "../../../../actions/admin/order.actions";

/* Util */
import util from "../../../../util/util";

const { Field, Label, Control, Input } = Form;
const { Column } = Columns;
const { Group } = Button;

const OrderAdd = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const coupons = useSelector(selectCustomerCoupons);
  const [loading, setLoading] = useState(false);
  const [order, setOrder] = useState({
    referenceNumber: null,
    amount: null,
    orderDate: moment().format("YYYY/MM/DD"),
    couponCode: "",
  });

  const checkIfCouponExist = () => {
    let exist = false;
    if (!util.isEmpty(order.couponCode) && coupons && coupons.length > 0) {
      const couponIndex = coupons.findIndex(
        (x) => x.CouponCode === order.couponCode
      );
      exist = couponIndex > -1 ? true : false;
    }
    return exist;
  };

  const onInputChange = (e) => {
    setOrder({ ...order, [e.target.name]: e.target.value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    if (
      (!util.isEmpty(order.couponCode) && checkIfCouponExist()) ||
      util.isEmpty(order.couponCode)
    ) {
      const nOrder = addOrder(id, order);
      nOrder
        .then((res) => {
          if (res.status === 200) {
            const { data } = res;

            if (data.order.id > 0) {
              dispatch(setCustomerOrdersAndCoupons(data));
              setTimeout(() => {
                AlertSuccessSave("Order has been save!", () => {
                  setLoading(false);
                  history.push(`/customers/${id}`);
                });
              }, 1000);
            }
          }
        })
        .catch((err) => {
          const { data } = err.response;
          if (data && data.err) {
            AlertError("Oops!", data.message);
          }
          setLoading(false);
        });
    } else if (!util.isEmpty(order.couponCode) && !checkIfCouponExist()) {
      AlertError("Oops!", "Coupon Code doesn't exist!");
      setLoading(false);
    }
  };

  return (
    <Fragment>
      <header>
        <Heading size={3} textColor="primary" className="pb-3">
          Add Order
        </Heading>
      </header>
      {loading ? <Loading /> : null}
      <Box>
        <form onSubmit={onSubmit}>
          <Columns>
            <Column>
              <Field>
                <Label>Order Slip #</Label>
                <Control>
                  <Input
                    name="referenceNumber"
                    defaultValue={order.referenceNumber}
                    onChange={onInputChange}
                    placeholder="Order Slip #"
                    required={true}
                  />
                </Control>
              </Field>
              <Field>
                <Label>Order Amount</Label>
                <Control>
                  <Input
                    name="amount"
                    defaultValue={order.amount}
                    onChange={onInputChange}
                    placeholder="Order Amount #"
                    type="number"
                    step="0.01"
                    required={true}
                  />
                </Control>
              </Field>
            </Column>
            <Column>
              <Field>
                <Label>Order Date</Label>
                <Control>
                  <Input
                    name="orderDate"
                    defaultValue={order.orderDate}
                    onChange={onInputChange}
                    placeholder="Order Date"
                    type="date"
                    min="2021-01-01"
                    max="2030-12-31"
                    required={true}
                  />
                </Control>
              </Field>
              <Field>
                <Label>Coupon Code</Label>
                <Control>
                  <Input
                    name="couponCode"
                    defaultValue={order.couponCode}
                    onChange={onInputChange}
                    placeholder="Coupon Code"
                  />
                </Control>
              </Field>
            </Column>
          </Columns>
          <Group>
            <Button color="primary" disabled={loading}>
              Save
            </Button>
          </Group>
        </form>
      </Box>
    </Fragment>
  );
};

export default OrderAdd;
