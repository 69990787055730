/* Packages */
import { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table, Heading, Box, Button } from "react-bulma-components";
import { useHistory } from "react-router-dom";
import moment from "moment";

/* Components */
import Loading from "../../../layout/common/loading.component";

/* Actions */
import {
  setOrders,
  selectOrders,
  setOrder,
} from "../../../../reducers/order.reducer";
import { getOrders } from "../../../../actions/admin/order.actions";

/* Util */
import util from "../../../../util/util";

const { Container } = Table;
const { Group } = Button;

const Orders = () => {
  const history = useHistory();
  const orders = useSelector(selectOrders);
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();

  const onClickView = (id) => {
    dispatch(setOrder(id));
    history.push(`/orders/${id}`);
  };

  useEffect(() => {
    const response = getOrders();
    response.then((res) => {
      if (res.status === 200) {
        dispatch(setOrders(res.data));
        setIsLoading(false);
      }
    });
  }, []);

  return (
    <Fragment>
      <header>
        <Heading size={3} textColor="primary" className="pb-3">
          Orders
        </Heading>
      </header>
      <Box>
        <Container>
          <Table bordered size="fullwidth">
            <thead>
              <tr>
                <th>Order Id</th>
                <th>Customer</th>
                <th>Reference #</th>
                <th>Amount</th>
                <th>Order Date</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {orders.length > 0 ? (
                orders.map((order) => (
                  <tr key={order.id}>
                    <td>{util.pad(order.id, 4)}</td>
                    <td>{`${order.FirstName} ${order.LastName}`}</td>
                    <td>{order.ReferenceNumber}</td>
                    <td>{order.Amount}</td>
                    <td>{moment(order.OrderDate).format("YYYY-MM-DD")}</td>
                    <td>
                      <Group size="small" align="center">
                        <Button
                          color="primary"
                          onClick={(e) => {
                            e.preventDefault();
                            onClickView(order.id);
                          }}
                        >
                          View
                        </Button>
                      </Group>
                    </td>
                  </tr>
                ))
              ) : isLoading ? null : (
                <tr>
                  <td colSpan="7" style={{ textAlign: "center" }}>
                    No Data
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
          {isLoading ? <Loading /> : null}
        </Container>
      </Box>
    </Fragment>
  );
};

export default Orders;
