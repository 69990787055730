import { Box } from "react-bulma-components";

const NotFound = () => {
  return (
    <Box style={{ width: 400, margin: "auto", marginTop: "1.5rem" }}>
      <h1 className="is-size-1 has-text-centered has-text-weight-bold">404</h1>
      <h1 className="is-size-2 has-text-centered has-text-weight-bold">
        Page Not Found!
      </h1>
    </Box>
  );
};
export default NotFound;
