/* Packages */
import { configureStore } from "@reduxjs/toolkit";

/* Reducers */
import authSlice from "./reducers/auth.reducer";
import customerSlice from "./reducers/customer.reducer";
import orderSlice from "./reducers/order.reducer";
import settingsReducer from "./reducers/settings.reducer";

export default configureStore({
  reducer: {
    auth: authSlice,
    customer: customerSlice,
    order: orderSlice,
    settings: settingsReducer,
  },
  devTools: process.env.NODE_ENV === "development",
});
