/* Packages */
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  sidebarToggle: false,
  sidebarCollapse: false,
};

export const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    setSidebarToggle: (state, action) => {
      state.sidebarToggle = action.payload;
    },
    setSidebarCollapse: (state, action) => {
      state.sidebarCollapse = action.payload;
    },
  },
});

export const { setSidebarToggle, setSidebarCollapse } = settingsSlice.actions;

export const selectSidebarToggle = (state) => state.settings.sidebarToggle;
export const selectSidebarCollapse = (state) =>
  state.settings.selectSidebarCollapse;

export default settingsSlice.reducer;
