/* Packages */
import { Fragment } from "react";
import { FaBars } from "react-icons/fa";
import { useDispatch } from "react-redux";

/* Components */
import PrivateRoute from "../../../util/privateRoute";
import Sidebar from "./sidebar.component";

/* Actions */
import { setSidebarToggle } from "../../../reducers/settings.reducer";

//onClick={() => handleToggleSidebar(true)}
const AdminLayout = ({ children }) => {
  const dispatch = useDispatch();

  return (
    <Fragment>
      <Sidebar />
      <main>
        <div
          className="btn-toggle"
          onClick={() => dispatch(setSidebarToggle(true))}
        >
          <FaBars />
        </div>
        {children}
      </main>
    </Fragment>
  );
};

const AdminLayoutRoute = ({ component: Component, ...rest }) => {
  return (
    <PrivateRoute
      {...rest}
      render={(props) => (
        <AdminLayout>
          <Component {...props} />
        </AdminLayout>
      )}
    />
  );
};

export default AdminLayoutRoute;
