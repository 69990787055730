import axios from "axios";

export const getCustomers = async () => {
  const res = await axios.get("/api/customer/admin");
  return res;
};

export const getCustomer = async (id) => {
  const res = await axios.get(`/api/customer/admin/${id}`);
  return res;
};

export const updateCustomer = async (id, data) => {
  const res = await axios.post(`/api/customer/admin/${id}`, data);
  return res;
};

export const searchCustomer = async (search) => {
  const res = await axios.post("/api/customer/admin/search", search);
  return res;
};
