/* Packages */
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  orders: [],
  order: {},
};

export const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    setOrders: (state, action) => {
      state.orders = action.payload;
    },
    setOrder: (state, action) => {
      if (typeof action.payload === "object") {
        state.order = action.payload;
      } else {
        const d = [...state.orders];
        state.order = d.filter((x) => x.id === action.payload)[0];
      }
    },
  },
});

export const { setOrders, setOrder } = orderSlice.actions;

export const selectOrders = (state) => state.order.orders;
export const selectOrder = (state) => state.order.order;

export default orderSlice.reducer;
