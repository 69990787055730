/* Components */
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

export const AlertSuccess = (title, message) => {
  MySwal.fire({
    title: `<h1>${title}</h1>`,
    text: message,
    icon: "success",
  });
};

export const AlertSuccessSave = (message, onClose) => {
  MySwal.fire({
    title: `<h3>Success</h3>`,
    text: message,
    icon: "success",
    showConfirmButton: false,
    timer: 1500,
    didClose: () => {
      onClose();
    },
    allowOutsideClick: false,
  });
};

export const AlertError = (title, message) => {
  MySwal.fire({
    title: `<h1>${title}</h1>`,
    text: message,
    icon: "error",
  });
};
