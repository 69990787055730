import axios from "axios";

export const getOrders = async () => {
  const res = await axios.get("/api/order/admin");
  return res;
};

export const getOrder = async (id) => {
  const res = await axios.get(`/api/order/admin/${id}`);
  return res;
};

export const addOrder = async (id, order) => {
  const res = await axios.post(`/api/order/admin/add/${id}`, order);
  return res;
};
