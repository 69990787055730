/* Packages */
import { useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Form, Columns } from "react-bulma-components";

/* Actions */
import { setCustomers } from "../../../../reducers/customer.reducer";
import { searchCustomer } from "../../../../actions/admin/customer.actions";

const { Field, Control, Input, Select } = Form;
const { Column } = Columns;

const SearchCustomer = () => {
  const dispatch = useDispatch();
  const [search, setSearch] = useState({
    type: "id",
    value: "",
  });

  const onInputChange = (e) => {
    setSearch({ ...search, [e.target.name]: e.target.value });
  };

  const onClickSubmit = (e) => {
    e.preventDefault();
    const result = searchCustomer(search);
    result.then((res) => {
      if (res.status === 200) {
        dispatch(setCustomers(res.data));
      }
    });
  };

  return (
    <form onSubmit={onClickSubmit}>
      <Columns>
        <Column size={1} centered={true}>
          <Field>
            <Control>
              <Select
                name="type"
                onChange={onInputChange}
                defaultValue={search.type}
              >
                <option value="id">Cust. ID</option>
                <option value="CouponCode">Reference</option>
                <option value="FirstName">First Name</option>
                <option value="LastName">Last Name</option>
                <option value="MobileNumber">Mobile Number</option>
              </Select>
            </Control>
          </Field>
        </Column>
        <Column size={10}>
          <Field>
            <Control>
              <Input
                name="value"
                defaultValue={search.value}
                onChange={onInputChange}
                placeholder="Search here ..."
                required={true}
              />
            </Control>
          </Field>
        </Column>
        <Column size={1}>
          <Button color="primary" fullwidth={true}>
            Search
          </Button>
        </Column>
      </Columns>
    </form>
  );
};

export default SearchCustomer;
