/* Packages */
import { createSlice } from "@reduxjs/toolkit";

/* util */
import util from "../util/util";

const initialState = {
  customers: [],
  customer: {},
};

export const customerSlice = createSlice({
  name: "customer",
  initialState,
  reducers: {
    setCustomers: (state, action) => {
      const uCustomer = { ...state.customer };
      if (!util.isObjEmpty(uCustomer)) {
        const nCustomers = action.payload;
        const customerIndex = nCustomers.findIndex(
          (c) => c.id === uCustomer.id
        );
        nCustomers[customerIndex] = uCustomer;
        state.customers = nCustomers;
      } else {
        state.customers = action.payload;
      }
    },
    setCustomer: (state, action) => {
      if (typeof action.payload === "object") {
        state.customer = action.payload;
      } else {
        const d = [...state.customers];
        state.customer = d.filter((x) => x.id === action.payload)[0];
      }
    },
    setCustomerOrdersAndCoupons: (state, action) => {
      const uCustomer = {
        ...state.customer,
        orders: state.customer.orders
          ? [...state.customer.orders, action.payload.order]
          : [action.order],
        coupons: state.customer.coupons
          ? util.isObjEmpty(action.payload.coupon)
            ? [...state.customer.coupons]
            : [...state.customer.coupons, action.payload.coupon]
          : [action.payload.coupons],
      };
      state.customer = uCustomer;

      const nCustomers = [...state.customers];
      const customerIndex = nCustomers.findIndex((c) => c.id === uCustomer.id);
      nCustomers[customerIndex] = uCustomer;

      state.customers = nCustomers;
    },
    setCustomerCouponAsClaimed: (state, action) => {
      const cCoupons = [...state.customer.coupons];
      const claimedIndex = cCoupons.findIndex(
        (coupon) => coupon.id === action.id
      );
      cCoupons[claimedIndex] = action.payload;
      const uCustomer = {
        ...state.customer,
        coupons: cCoupons,
      };
      state.customer = uCustomer;

      const nCustomers = [...state.customers];
      const customerIndex = nCustomers.findIndex((c) => c.id === uCustomer.id);
      nCustomers[customerIndex] = uCustomer;

      state.customers = nCustomers;
    },
  },
});

export const {
  setCustomers,
  setCustomer,
  setCustomerOrdersAndCoupons,
  setCustomerCouponAsClaimed,
} = customerSlice.actions;

export const selectCustomers = (state) => state.customer.customers;
export const selectCustomer = (state) => state.customer.customer;
export const selectCustomerCoupons = (state) => {
  const customer = { ...state.customer.customer };
  const coupons = customer && customer.coupons ? customer.coupons : null;

  return coupons;
};

export default customerSlice.reducer;
