/* Packages */
import axios from "axios";
import jwt_decoded from "jwt-decode";

import setAuthToken from "../../util/setAuthToken";

export const login = async (data) => {
  let decoded = null;
  const res = await axios.post("/api/user/login", data);
  if (res.status === 200 && res.data.success) {
    const { token } = res.data;
    localStorage.setItem("jwtToken", token);
    setAuthToken(res.data.token);
    decoded = jwt_decoded(token);
  }

  return decoded;
};
