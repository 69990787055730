/* Packages */
import { useState, Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table, Heading, Box, Button } from "react-bulma-components";
import { useHistory } from "react-router-dom";
import moment from "moment";

/* Components */
import Loading from "../../../layout/common/loading.component";
import SearchCustomer from "./customer.search.component";

/* Actions */
import {
  setCustomers,
  selectCustomers,
  setCustomer,
} from "../../../../reducers/customer.reducer";
import { getCustomers } from "../../../../actions/admin/customer.actions";

/* Util */
import util from "../../../../util/util";

const { Container } = Table;
const { Group } = Button;

const Customer = () => {
  const history = useHistory();
  const customers = useSelector(selectCustomers);
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();

  const onClickView = (id) => {
    dispatch(setCustomer(id));
    history.push(`/customers/${id}`);
  };

  useEffect(() => {
    if (customers.length < 1) {
      const response = getCustomers();
      response.then((res) => {
        if (res.status === 200) {
          dispatch(setCustomers(res.data));
          setIsLoading(false);
        }
      });
    } else {
      setIsLoading(false);
    }
  }, []);

  return (
    <Fragment>
      <header>
        <Heading size={3} textColor="primary" className="pb-3">
          Customers
        </Heading>
      </header>
      <Box>
        <SearchCustomer />
        <Container mt={3}>
          <Table bordered size="fullwidth">
            <thead>
              <tr>
                <th>Customer ID</th>
                <th>Name</th>
                <th>Mobile Number</th>
                <th>Email</th>
                <th>Validated</th>
                <th>Date Registered</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {customers.length > 0 ? (
                customers.map((customer) => (
                  <tr key={customer.id}>
                    <td>{util.pad(customer.id, 4)}</td>
                    <td>{`${customer.FirstName} ${customer.LastName}`}</td>
                    <td>{customer.MobileNumber}</td>
                    <td>{customer.Email}</td>
                    <td>{customer.Activated ? "Yes" : "No"}</td>
                    <td>
                      {moment(customer.createdAt).format("YYYY-MM-DD hh:mm A")}
                    </td>
                    <td>
                      <Group size="small" align="center">
                        <Button
                          color="primary"
                          onClick={(e) => {
                            e.preventDefault();
                            onClickView(customer.id);
                          }}
                        >
                          View
                        </Button>
                      </Group>
                    </td>
                  </tr>
                ))
              ) : isLoading ? null : (
                <tr>
                  <td colSpan="7" style={{ textAlign: "center" }}>
                    No Data
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
          {isLoading ? <Loading /> : null}
        </Container>
      </Box>
    </Fragment>
  );
};

export default Customer;
