/* Packages */
import { useState, useEffect } from "react";
import { Box, Form, Button, Heading } from "react-bulma-components";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";

/* Actions */
import { setUser, isLoggedIn } from "../../../../reducers/auth.reducer";
import { login } from "../../../../actions/admin/auth.actions";

/* Others */
import util from "../../../../util/util";
import { AlertError } from "../../../layout/common/alerts-component";

const { Field, Label, Control, Input } = Form;
const { Group } = Button;

const validate = ({ username, password }) => {
  let err = {};

  if (username === "" || username === null) {
    err = { ...err, username: "This field is required" };
  }

  if (password === "" || password === null) {
    err = { ...err, password: "This field is required" };
  }

  return err;
};

const Login = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const isAuth = useSelector(isLoggedIn);
  const [data, setData] = useState({ username: "", password: "" });
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (isAuth) history.push("/customers");
  });

  const onSubmit = (e) => {
    e.preventDefault();
    const err = validate(data);

    if (util.isObjEmpty(err)) {
      const auth = login(data);
      auth
        .then((decoded) => {
          dispatch(setUser(decoded));
        })
        .catch((error) => {
          const { data } = error.response;
          if (data.err) {
            AlertError("Oops!", data.message);
          }
        });
    } else {
      setErrors(err);
    }
  };

  const onInputChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  return (
    <Box style={{ width: 400, margin: "auto", marginTop: "1.5rem" }}>
      <Heading
        className="has-text-primary"
        size={3}
        style={{ marginTop: ".5rem" }}
      >
        Login
      </Heading>
      <form onSubmit={onSubmit}>
        <Field>
          <Label className="has-text-primary">Username</Label>
          <Control>
            <Input
              type="text"
              name="username"
              placeholder="Username"
              value={data.username}
              onChange={onInputChange}
              color={errors.username ? "danger" : "default"}
            />
          </Control>
          {errors.username ? (
            <p className="help is-danger">{errors.username}</p>
          ) : null}
        </Field>
        <Field>
          <Label className="has-text-primary">Password</Label>
          <Control>
            <Input
              type="password"
              name="password"
              placeholder="Password"
              value={data.password}
              onChange={onInputChange}
              color={errors.password ? "danger" : "default"}
            />
          </Control>
          {errors.password ? (
            <p className="help is-danger">{errors.password}</p>
          ) : null}
        </Field>
        <Group align="center">
          <Button color="primary" fullwidth={true}>
            Login
          </Button>
        </Group>
      </form>
    </Box>
  );
};

export default Login;
