import axios from "axios";

const signup = async (data) => {
  const res = await axios.post("/api/customer/signup", data);

  return res;
};

const validateOtp = async (data) => {
  const res = await axios.post("/api/customer/validate-otp", data);
  return res;
};

export { signup, validateOtp };
